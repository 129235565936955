import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import GetAppIcon from "@mui/icons-material/GetApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { GlobalContext } from "../context/GlobalContext";
import { Formik, Form, Field } from "formik";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import { useCookies } from "react-cookie";
import Plantilla from "../Dowloads/Plantilla.xlsx";
import PlantillaBikes from "../Dowloads/PlantillaBikes.xls";
import PlantillaSegumak from "../Dowloads/Plantilla-Segumak.xlsx";
import { useFormatTransferItems } from "../utils/formatedLines";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../styles/select-search.css";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import Swal from "sweetalert2";
import Excel from "./Excel";

const TableArticles = () => {
  const { Articulos, deleteArticle, updateArticle, deleteAllArticle } =
    useContext(GlobalContext);
  const params = useParams();
  const rows = Articulos;

  const [respuestasOk, setRespuestasOk] = useState([]);
  const [selectRubros, setSelectRubros] = useState([]);
  const [selectSubRubros, setSelectSubRubros] = useState([]);
  const [selectMarcas, setSelectMarcas] = useState([]);
 // eslint-disable-next-line no-unused-vars
 const [selectModelos, setSelectModelos] = useState([]);
  const [cookies, removeCookie] = useCookies();

  const ArticuloFound = Articulos.find((Articulo) => Articulo.id === params.id);


  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


  const [open, setOpen] = React.useState(false);
  const [errorItemstoSap, setErrorItemstoSap] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const loadOptions = async (inputValue, callback) => {
    const queryOptions = {
      filter: `CardType eq 'cSupplier' and startswith(CardName, '${inputValue.toUpperCase()}' )`,
    };
    if (inputValue.length > 2) {
      const response = await axios.post(
        "http://masterapi.yuhmak.com/api/v1/all/BusinessPartners",
        queryOptions
      );

      const json = await response.data.details;

      callback(
        json.map(({ CardCode, CardName }) => ({
          label: CardName,
          value: CardCode,
        }))
      );
    }
  };

  let getMarcas = async () => {
    try {
      getMarcas = await axios
        .post("http://masterapi.yuhmak.com/api/v1/all/MARCAS")
        .then((response) =>
          response.data.details.statusCode === 401
            ? window.location.assign("/") &&
              removeCookie("Division") &&
              localStorage.removeItem("userName")
            : setSelectMarcas(response.data.details)
        );
    } catch (error) {}
  };

  let getSubrubros = async (U_Rubro) => {
    const queryOptions = { filter: U_Rubro };
    try {
      getRubros = await axios
        .post(
          "http://masterapi.yuhmak.com/api/v1/all/SUBRUBROS",
          queryOptions
        )
        .then((response) => {
          setSelectSubRubros(response.data.details);
        });
    } catch (error) {}
  };

  let getRubros = async () => {
    try {
      getRubros = await axios
        .post("http://masterapi.yuhmak.com/api/v1/all/RUBROS")
        .then((response) => setSelectRubros(response.data.details));
    } catch (error) {}
  };

  let getModelos = async () => {
    try {
      getModelos = await axios
        .post("http://masterapi.yuhmak.com/api/v1/all/MODELOS")
        .then((response) => setSelectModelos(response.data.details));
        //console.log("selectModelos",selectModelos)
    } catch (error) {
      console.log(error);
    }
  };

  
  useEffect(() => {
    if (handleOpen) {
      getRubros();
      getMarcas();
      loadOptions2();
      getModelos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadOptions2 = async (inputValue, callback) => {
    const queryOptions = {
      filter: `startswith(GroupName, '${inputValue?.toUpperCase()}' )`,
      select: "Number, GroupName",
    };
    if (inputValue?.length > 2) {
      const response = await axios.post(
        "http://masterapi.yuhmak.com/api/v1/all/ItemGroups",
        queryOptions
      );

      const json = await response.data.details;

      callback(
        json.map(({ Number, GroupName }) => ({
          label: GroupName,
          value: Number,
        }))
      );
    }
  };

  const selectRubrosMap = selectRubros?.map(({ Code, Name }) => ({
    value: Code,
    label: Name,
  }));
  const selectSubRubrosMap = selectSubRubros?.map(({ Code, Name }) => ({
    value: Code,
    label: Name,
  }));
  const selectMarcasMap = selectMarcas?.map(({ Code, Name }) => ({
    value: Code,
    label: Name,
  }));

/*   const selectModelosMap = selectModelos?.map(({code,name})=>({
    value: code,
    label: name,
  })) */

  

  useEffect(() => {
    rows?.length === 0 && setErrorItemstoSap([]);
  }, [rows]);

  const { formatedTransferItems, materialRevaluationLines } =
    useFormatTransferItems();

  console.log("formatedTransferItems->", formatedTransferItems);
  console.log("materialRevaluationLines->", materialRevaluationLines);
  useEffect(() => {
    if (Articulos.length > 0) {
      localStorage.setItem("Articulos", JSON.stringify(Articulos));
    }
  }, [Articulos]);

 // const responses = [];
 // const responsesFails = [];

  function borrarArticulo(id) {
    Swal.fire({
      title: "Borrar el articulo?",
      showDenyButton: true,
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Borrar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteArticle(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Articulo borrado",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (result.isDenied) {
      }
    });
  }

  function borrarAllArticulos(rows) {
    Swal.fire({
      title: "Borrar todos los articulos?",
      showDenyButton: true,
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Borrar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAllArticle(rows);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Articulos borrados",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (result.isDenied) {
      }
    });
  }

  //console.log("formatedTransferItems", formatedTransferItems);//oooooo

  async function sendToSap() {
    let timerInterval;
    Swal.fire({
      title: "Creando Articulos..",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {}, 100);
      },
      willClose: () => {},
    });
  
    const promises = [];
    const responses = [];
    const responsesFails = [];
  
    const removeProps = (object, keys) => {
      return Object.entries(object).reduce(
        (a, [key, value]) =>
          keys.indexOf(key) === -1 ? { ...a, [key]: value } : a,
        {}
      );
    };
  
    for (let i = 0; i < formatedTransferItems.length; i++) {
      const localTimerInterval = timerInterval;
  
      console.log("U_rubro.value->", formatedTransferItems[i].U_Rubro);
      try {
        // Primer POST a /Items
        const postDataEndpoint = "http://masterapi.yuhmak.com/api/v1/postData/Items";
        const responseItems = await axios.post(
          postDataEndpoint,
          removeProps(formatedTransferItems[i], ["id", "U_ano", "U_importe","U_costo"])
        );
  
        const { details } = responseItems.data;
        if (responseItems.data.statusCode === 200) {
          // Según las condiciones, realiza la segunda llamada
          if (
            cookies.Division === "DM" &&
            formatedTransferItems[i].U_Rubro === "000016"
          ) {
            const lastMoto = await axios.post(
              "http://masterapi.yuhmak.com/api/v1/all/listaespmotos",
              {
                orderby: "DocEntry desc",
                top: 1,
              }
            );
  
            const lastCodeMoto = lastMoto.data.details[0]
              ? parseInt(lastMoto.data.details[0].Code)
              : 0;
            const newCodeMoto = (lastCodeMoto + 1).toString();
  
            const newMotoList = {
              Code: newCodeMoto,
              Name: formatedTransferItems[i].ItemName,
              U_ano: formatedTransferItems[i].U_ano,
              U_articulo: formatedTransferItems[i].ItemCode,
              U_importe: formatedTransferItems[i].U_importe,
              U_costo: formatedTransferItems[i].U_costo,
            };
  
            const responseListaespmotos = await axios.post(
              "http://masterapi.yuhmak.com/api/v1/postData/listaespmotos",
              newMotoList
            );
            console.log("Nueva moto creada:", responseListaespmotos.data);
          } else {
            // Tercer POST a /MaterialRevaluation si es necesario

            const materialRevaluations = materialRevaluationLines.map((obj) => ({
              Comments: "Revalorizacion Automatica",
              DataSource: "I",
              MaterialRevaluationLines: [
                {
                  ItemCode: details.ItemCode,
                  Price: obj?.MaterialRevaluationLines[0]?.Price?.replace(
                    ",",
                    "."
                  ),
                  WarehouseCode: obj?.MaterialRevaluationLines[0].WarehouseCode,
                  Project: obj?.MaterialRevaluationLines[0].Project,
                },
              ],
              RevalType: "P",
            }));
  
            const responseMaterialRevaluation = await axios.post(
              "http://masterapi.yuhmak.com/api/v1/postData/MaterialRevaluation",
              materialRevaluations[i]
            );
            console.log("MaterialRevaluation creada:", responseMaterialRevaluation.data);
          }
  
          responses.push({
            ItemName: details.ItemName,
            ItemCode: details.ItemCode,
            Revalorizacion: "Operación exitosa",
          });
  
          clearInterval(localTimerInterval);
          deleteArticle(formatedTransferItems[i]?.id);
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
  
        const errorMessage =
          error.response && error.response.data && error.response.data.details;
        Swal.fire({
          title: "Error al cargar artículo",
          icon: "error",
          text: errorMessage,
        });
        responsesFails.push(
          `ARTICULO: ${formatedTransferItems[i]?.ItemName} ===> ERROR: ${errorMessage}, `
        );
        setErrorItemstoSap(responsesFails);
      }
    }
  
    // Código después de setErrorItemstoSap
    Promise.all(promises).then(() => {
      setRespuestasOk(responses);
      console.log("promises->", promises);
      console.log("responses->", responses);
  
      let iterator;
      let message = "";
  
      const result = {
        title: "",
        text: "",
        icon: "",
      };
  
      iterator = responses;
  
      for (let index = 0; index < iterator.length; index++) {
        message += `${iterator[index]} \n`;
      }
  
      result.text = message;
      if (responses.length > 0 && responsesFails.length > 0) {
        let message = "Error en el/los artículo(s):\n";
        responsesFails.map((el) => (message += el));
        const newMessage = message.slice(0, -2);
  
        Swal.fire({
          title: "Error al cargar algunos artículo",
          icon: "warning",
          text: newMessage,
        });
      } else if (responses.length > 0) {
        Swal.fire({
          title: "Artículo Creado",
          icon: "success",
        });
      } else {
        let message = "Error en el/los artículo(s):\n";
        responsesFails.map((el) => (message += el));
        const newMessage = message.slice(0, -2);
  
        Swal.fire({
          title: "Error al cargar artículo",
          icon: "error",
          text: newMessage,
        });
      }
    });
  }
  return (
    <>
       {cookies.Division === "DR" ||
      cookies.Division === "DH" ||
      cookies.Division === "DN" ? (
        <div className="plantilla-link">
          <Excel />
          {cookies.Division !== "DM" && (
            <Tooltip title="Descargar Plantilla" placement="top">
              <a href={Plantilla} download="Plantilla.xlsx">
                <Button variant="contained" color="primary">
                  <GetAppIcon sx={{ fontSize: 25 }} />
                  &nbsp; PLANTILLA
                </Button>
              </a>
            </Tooltip>
          )}
        </div>
      ) : cookies.Division === "DB" ? (
        <div className="plantilla-link">
          <Excel />
          {cookies.division !== "DM" && ( // Añade esta condición
            <Tooltip title="Descargar Plantilla" placement="top">
              <a href={PlantillaBikes} download="PlantillaBikes.xlsx">
                {" "}
                <Button variant="contained" color="primary">
                  <GetAppIcon sx={{ fontSize: 25 }} />
                  &nbsp; PLANTILLA
                </Button>{" "}
              </a>
            </Tooltip>
          )}
        </div>
      ) : cookies.Division === "DSG" ? (
        <div  className="plantilla-link">
          <Excel />
          
            <Tooltip title="Descargar Plantilla" placement="top">
              <a href={PlantillaSegumak} download="Plantilla-Segumak.xlsx">
                {" "}
                <Button variant="contained" color="primary">
                  <GetAppIcon sx={{ fontSize: 25 }} />
                  &nbsp; PLANTILLA
                </Button>{" "}
              </a>
            </Tooltip>
        </div>
      ) : null}

      <div className="table-container">
        <Typography variant="h4" className="text-table">
          Articulos Pendientes de Carga
        </Typography>

        <div>
          <Paper sx={{ width: "100%", overflowY: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                     <TableCell><b>Articulo</b></TableCell>
                    <TableCell align="right"><b>Marca</b></TableCell>
                    <TableCell align="right"><b>Proveedor</b></TableCell>
                    <TableCell align="right"><b>Rubro</b></TableCell>
                    <TableCell align="right"><b>Subrubro</b></TableCell>
                    {cookies.Division ==="DSG"&&(<TableCell align="right"><b>Cod Articulo</b></TableCell>)}
                    <TableCell align="right"><b>Grupo</b></TableCell>
                      {
                        cookies.Division === "DM" &&(
                        <TableCell align="right"> <b>Modelo</b></TableCell>)
                          ?(
                          <TableCell align="right"><b>Año</b></TableCell>)
                          :null
                      }
                    <TableCell align="right"><b>Costo</b></TableCell>
                    {
                      cookies.Division === "DSG"? (
                        <TableCell align="right"><b>Base</b></TableCell>
                      ) :
                    <TableCell align="right"><b>Factor</b></TableCell>
                    }
                    <TableCell align="right"><b>Cod. Fabricante</b></TableCell>
                    <TableCell align="right"><b>Cod. Barras</b></TableCell>
                    <TableCell align="right"><b>IVA</b></TableCell>
                    <TableCell>
                      {rows.length > 0 ? (
                        <Tooltip title="BORRAR TODOS" placement="left">
                          <Button onClick={() => borrarAllArticulos(rows)}>
                            <HighlightOffIcon></HighlightOffIcon>
                          </Button>
                        </Tooltip>
                      ) : (
                        null
                      )}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.ItemName?.toUpperCase()}
                      </TableCell>
                      <TableCell align="right">{row?.U_Marca?.value}</TableCell>
                      <TableCell align="right">{row?.Mainsupplier?.value}</TableCell>
                      <TableCell align="right">{row?.U_Rubro?.value}</TableCell>
                      <TableCell align="right">{row?.U_Subrubros?.value}</TableCell>
                      {cookies.Division ==="DSG"&&(<TableCell align="right">{row.ItemCode}</TableCell>)}
                      <TableCell align="right">{row?.ItemsGroupCode?.value}</TableCell>
                      <TableCell align="right">{row?.ItemPrices[0].Price}</TableCell>
                      {
                        cookies.Division === "DSG"? (
                          <TableCell align="right">{row?.ItemPrices[1]?.Price}</TableCell>
                        ):
                        <TableCell align="right">{row?.ItemPrices[0]?.Factor}</TableCell>
                      }
                      <TableCell align="right">{row?.SupplierCatalogNo}</TableCell>
                      <TableCell align="right">{row?.BarCode}</TableCell>
                      <TableCell align="right">{row?.ApTaxCode} </TableCell>
                      <TableCell>
                        <Tooltip title="BORRAR ARTICULO" placement="left">
                          <Button onClick={() => borrarArticulo(row?.id)}>
                            <HighlightOffIcon></HighlightOffIcon>
                          </Button>
                        </Tooltip>

                        <Tooltip title="EDITAR" placement="left">
                          <Button
                            onClick={() => {
                              handleOpen();
                            }}
                          >
                            <Link to={`/FormArticles/edit/${row?.id}`}>
                              <EditIcon></EditIcon>
                            </Link>
                          </Button>
                        </Tooltip>

                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx id="modal-edited">
                            <Button
                              onClick={handleClose}
                              sx={{
                                position: "absolute",
                                right: 0,
                                fontSize: 25,
                              }}
                            >
                              X
                            </Button>

                            <Formik
                              initialValues={ArticuloFound}
                              validate={(valores) => {
                                let errores = {};

                                if (!valores.ItemName) {
                                  console.log(" ");
                                } else if (
                                  !/^[A-Za-z0-9/\-()\\\sáéíóúÁÉÍÓÚÑñ.+]{3,100}$/.test(
                                    valores.ItemName
                                  )
                                ) {
                                  errores.ItemName = "Caracteres invalidos";
                                }
                                return errores;
                              }}
                              onSubmit={(valores, { resetForm }) => {
                                updateArticle(valores);
                                handleClose();
                                window.location.assign("/#/FormArticles");
                                resetForm();
                              }}
                            >
                              {({
                                values,
                                errors,
                                setFieldValue,
                                handleChange,
                                handleBlur,
                              }) => (
                                <Form className="form-edit">
                                  <p className="title-edit">Editar Articulo</p>
                                  <TextField
                                    margin="normal"
                                    fullWidth
                                    id="ItemName"
                                    type="text"
                                    value={values?.ItemName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="ItemName"
                                    placeholder="Descripción del artículo"
                                    autoFocus
                                  />
                                  {errors.ItemName && (
                                    <div className="textError">
                                      {errors.ItemName}
                                    </div>
                                  )}

                                  <AsyncSelect
                                    value={values?.Mainsupplier}
                                    loadOptions={loadOptions}
                                    id={"Mainsupplier"}
                                    type={"text"}
                                    onBlur={handleBlur}
                                    onChange={(option) =>
                                      setFieldValue("Mainsupplier", option)
                                    }
                                    placeholder="Proveedor"
                                  />
                                  <div className="textProv">
                                    Escriba 3 caracteres para empezar a buscar..
                                  </div>
                                  <br />

                                  <Select
                                    options={selectMarcasMap}
                                    placeholder="Marca"
                                    id={"U_Marca"}
                                    type={"text"}
                                    value={values?.U_Marca}
                                    onChange={(option) =>
                                      setFieldValue("U_Marca", option)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <br />

                                  <Select
                                    options={selectRubrosMap}
                                    placeholder="Rubro"
                                    id={"U_Rubro"}
                                    type={"text"}
                                    value={values?.U_Rubro}
                                    onChange={(option) => {
                                      values.U_Subrubros = {
                                        value: "",
                                        label: "--Seleccionar Subrubro--",
                                      };
                                      setFieldValue("U_Rubro", option);
                                      getSubrubros(
                                        `U_Rubro eq '${option.value}'`
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />

                                  <br />
                                  <Select
                                    options={selectSubRubrosMap}
                                    search
                                    placeholder="Subrubros"
                                    id={"U_Subrubros"}
                                    type={"text"}
                                    value={values?.U_Subrubros}
                                    onChange={(option) =>
                                      setFieldValue("U_Subrubros", option)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <br />

                                  <AsyncSelect
                                    value={values?.ItemsGroupCode}
                                    loadOptions={loadOptions2}
                                    id={"ItemsGroupCode"}
                                    type={"text"}
                                    onBlur={handleBlur}
                                    onChange={(option) =>
                                      setFieldValue("ItemsGroupCode", option)
                                    }
                                    placeholder="Grupo de Articulos"
                                  />
                                
                                  <TextField
                                    id="ItemPrices[0].Factor"
                                    type="number"
                                    value={values?.ItemPrices[0]?.Factor}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="ItemPrices[0].Factor"
                                    placeholder="Factor"
                                    helperText="Solo números y puntos."
                                  />
                                  <TextField
                                    id="ItemPrices[1].Price"
                                    value={values.ItemPrices[1].Price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="ItemPrices[1].Price"
                                    placeholder="Costo"
                                    helperText="Solo números y puntos."
                                    type="number"
                                  />

                                  <TextField
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    id="SupplierCatalogNo"
                                    name="SupplierCatalogNo"
                                    placeholder="Cod.Fabricante"
                                    value={values?.SupplierCatalogNo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoFocus
                                    inputProps={{
                                      maxLength: 16,
                                    }}
                                  />

                                  <TextField
                                    margin="normal"
                                    fullWidth
                                    id="BarCode"
                                    name="BarCode"
                                    placeholder="Cod.Barras"
                                    value={values?.BarCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoFocus
                                    type="number"
                                    onInput={(e) => {
                                      e.target.value = Math.max(
                                        0,
                                        parseInt(e.target.value)
                                      )
                                        .toString()
                                        .slice(0, 13);
                                    }}
                                  />

                                  <br />

                                  <Field
                                    type="radio"
                                    name="ApTaxCode"
                                    value="IVA_21"
                                  />
                                  <b className="input_iva">IVA_21</b>

                                  <Field
                                    type="radio"
                                    name="ApTaxCode"
                                    value="IVA_10.5"
                                  />
                                  <b>IVA_10.5</b>
                                  {values?.ApTaxCode &&
                                  values?.U_Rubro &&
                                  values?.ItemName &&
                                  values?.U_Subrubros?.value !== "" &&
                                  values?.Mainsupplier &&
                                  errors?.ItemName !==
                                    "Caracteres invalidos" ? (
                                    <Button
                                      onClick={() =>
                                        (values.ArTaxCode = values.ApTaxCode) &&
                                        (values.SWW =
                                          values.SupplierCatalogNo) &&
                                        updateArticle(ArticuloFound)
                                      }
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      sx={{ mt: 3, mb: 2 }}
                                    >
                                      Guardar
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        (values.ArTaxCode = values.ApTaxCode)
                                      }
                                      type="submit"
                                      fullWidth
                                      disabled
                                      variant="contained"
                                      sx={{ mt: 3, mb: 2 }}
                                    >
                                      Guardar
                                    </Button>
                                  )}
                                </Form>
                              )}
                            </Formik>
                          </Box>
                        </Modal>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {rows.length > 0 ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={sendToSap}
            >
              Crear
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              disabled
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={sendToSap}
            >
              Crear
            </Button>
          )}
        </div>
      </div>

      {rows?.length > 0 && errorItemstoSap?.length > 0
        ? errorItemstoSap.map((msg) => (
            <div style={{ color: "red" }}>{msg}</div>
          ))
        : ""}
      <div style={{ height: "100px" }}></div>

      {respuestasOk.length > 0 ? (
        <>
          <Typography component="h1" variant="h4" className="text-table">
            Articulos Creados
          </Typography>

          <Paper sx={{ width: "100%", overflowY: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Producto</b>
                    </TableCell>
                    <TableCell>
                      <b>Cod. SAP</b>
                    </TableCell>
                    <TableCell>
                      <b>Revalorizacion</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {respuestasOk?.map((row) => (
                    <TableRow key={row?.ItemCode}>
                      <TableCell>{row?.ItemName?.toUpperCase()}</TableCell>
                      <TableCell>{row?.ItemCode}</TableCell>
                      <TableCell>{row?.Revalorizacion}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <div
            style={{
              padding: " 1em 0",
              display: "flex",
              flexDirection: "row-reverse",
            }}
            className="button-export"
          >
            <ExcelFile
              element={
                <Button variant="contained" color="success">
                  {" "}
                  <GetAppIcon sx={{ fontSize: 25 }} /> &nbsp; Exportar a Excel
                </Button>
              }
              filename="Articulos Creados"
            >
              <ExcelSheet data={respuestasOk} name="Articulos Creados Excel">
                <ExcelColumn label="Articulo" value="ItemName" />
                <ExcelColumn label="ItemCode" value="ItemCode" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default TableArticles;
