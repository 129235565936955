import React, { useContext } from 'react'
import { useCookies } from "react-cookie"
import { GlobalContext } from "../context/GlobalContext";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import Tooltip  from "@mui/material/Tooltip";
import FileUploadIcon from '@mui/icons-material/FileUpload';

import * as XLSX from 'xlsx'

const Excell = () => {

    const xlsx = XLSX
    const [cookies] = useCookies();
    const { addArticle } = useContext(GlobalContext);

    const Input = styled('input')({
      display: 'none',
    });
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);

                console.log("soy json antes de if",json)
                
                if( json.length > 0 ) {
                  console.log('json', json.map((obj => obj.Articulo)))

                  let  excelFormated =
                  
                  json.map((obj) => {
                    console.log("soy obj excel",obj)
                    
                    return {
                      ItemCode: obj?.CodigoArticulo,
                      ItemName: obj?.Articulo,
                      ItemsGroupCode: {
                        label: 'Grupo De Articulo',
                        value:obj?.GrupoDeArticulos
                      },
                      BarCode: obj?.CodBarras,
                      VatLiable: "tYES",
                      PurchaseItem: obj.ArtCompra ? (obj.ArtCompra.toLowerCase() === 'no'? "tNO" :"tYES") : "tYES",
                      SalesItem:  obj.ArtVenta ? (obj.ArtVenta.toLowerCase() === 'no'? "tNO" :"tYES") : "tYES",
                      InventoryItem:  obj.ArtInventariable ? ( obj.ArtInventariable.toLowerCase() === 'no'? "tNO" :"tYES") : "tYES",
                      Mainsupplier: {
                        label: 'Proveedor',
                        value: obj?.Proveedor
                      },
                      SupplierCatalogNo: obj?.CodFabricante,
                      ManageSerialNumbers: obj?.NroSerie || "tNO",
                      SalesUnit: "UNIDAD",
                      PurchaseUnit: "UNIDAD",
                      WTLiable: "tYES",
                      SWW: obj?.CodFabricante && obj.CodFabricante.length > 16 ? obj.CodFabricante.slice(0, 16) : obj?.CodFabricante, 
                      IndirectTax: "tYES",
                      ArTaxCode: obj.IVA, //Opciones: IVA_21 // IVA_10.5
                      ApTaxCode: obj.IVA, //MISMO VALOR ArTaxCode
                      InventoryUOM: "UNIDAD",
                      Series: parseInt(cookies?.Series),
                      Price: obj?.Costo,
                      ItemPrices: [
                        {
                          PriceList: 1,
        
                          Price:"" ,
        
                          Currency: cookies.database === "SEGUMAK_PRD"? "USD":"ARS",
        
                          BasePriceList: cookies.database === "SEGUMAK_PRD"? 1 : 13,
        
                          Factor:cookies.database === "SEGUMAK_PRD"? 1 : obj?.Factor,
                        },
                        {
                          PriceList: cookies.database === "SEGUMAK_PRD"? 2 : 13,
        
                          Price:cookies.database === "SEGUMAK_PRD"? obj?.Base: obj?.Costo,
        
                          Currency:"ARS",
        
                          BasePriceList:cookies.database === "SEGUMAK_PRD"? 1 : 13,
        
                          Factor: 1.0,
                        },
                      ],
                      UoMGroupEntry: 1,
                      InventoryUoMEntry: 1,
                      DefaultSalesUoMEntry: 1,
                      DefaultPurchasingUoMEntry: 1,
                      U_Division: cookies?.Division,
                      U_Rubro: {
                        label: 'Rubro',
                        value: obj?.Rubro
                      },
                      U_Subrubros: {
                        label: 'Subrubro',
                        value: obj?.Subrubro
                      },
                      U_Marca: {
                        label: 'Marca', 
                        value: obj?.Marca
                      },
                    }
                  })
                    for (let i = 0; i < excelFormated.length; i++) {
                      addArticle(excelFormated[i])

                    }                    

                   console.log('excelFormated', excelFormated)
        
                  }

            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }
    
    

  return (
    <div>
<Tooltip title="Cargar desde Excel" placement="top">
<label htmlFor="contained-button-file">
  <Input  onChange={readUploadFile} accept="*" id="contained-button-file" multiple type="file" />
  <Button variant="contained" color="success" component="span">
    
    <FileUploadIcon/>
    &nbsp;
   Excel
  </Button>
</label>

</Tooltip>
   
</div>
  )
}

export default Excell